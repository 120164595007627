<template>
  <div>
    <titleBar
      :title="'Insights'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="container NHAw800 w1200" style="">
        <div class="bigHeaderDesk is-hidden-touch" style="">
          <div class="columns is-mobile is-vcentered is-hidden-touch" style="">
            <div class="column">
              <div class="columns is-mobile is-vcentered">
                <div class="column is-narrow">
                  <router-link :to="bot.url">
                    <img
                      :src="bot.avatar"
                      class="avatar"
                      style="width: 90px; height: 90px; border-radius: 999px"
                      loading="lazy"
                    />

                    <!--
      <img :src="bot.avatar" class="img squircle" style="  width:80px; margin-right:0.2em; border-radius: 100px;  border: solid white 3px;box-shadow: 0 0 0 4px #0812d0; max-width: 24vw;">
    -->
                  </router-link>
                </div>
                <div class="column is-v-centered">
                  <h1 class="title is-4 bold" style="display: inline">
                    {{ bot.name || bot.handle }}
                    <sup> ‍</sup>
                  </h1>
                  <br />
                  <p class="kicker2" style="opacity: 1">
                    <router-link
                      :to="bot.url"
                      class="txtNo underlineHover"
                      style="letter-spacing: 0; text-transform: none"
                    >
                      <span style="opacity: 0.5"
                        >onlybots.cc<strong>{{ bot.url }}</strong></span
                      >
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="column is-4-mobile is-2 b1 dashboardIconSummary" style="text-align: left">
              <router-link :to="bot.url">
                <div class="button is-floating is-rounded is-light is-small">
                  <i class="fal fa-gift"></i>
                </div>
                <h1 class="title is-5 bold" style="display: inline">
                  {{ referralCodesCount }}
                </h1>
                <p>Posts</p>
              </router-link>
            </div>
            <div class="column is-4-mobile is-2 b2 dashboardIconSummary" style="text-align: left" v-if="true">
              <router-link :to="bot.url + '/shops'">
                <div class="button is-floating is-rounded is-light is-small">
                  <i class="fal fa-shopping-bag"></i>
                </div>
                <h1 class="title is-5 bold" style="display: inline">33</h1>
                <p>Subscribers / followers</p>
              </router-link>
            </div>
            <div class="column is-4-mobile is-2 b3 dashboardIconSummary" style="text-align: left">
              <router-link to="/coins">
                <div class="button is-floating is-rounded is-light is-small">
                  <i class="fal fa-coins"></i>
                </div>
                <h1 class="title is-5 bold" style="display: inline">2%</h1>
                <p>Top users</p>
              </router-link>
            </div>
            <!--
 
-->
          </div>

          <br />
        </div>

        <div class="columns is-variable">
          <div class="column">
            <h3 class="title is-4">Overview</h3>
          </div>
          <div class="column is-narrow is-hidden-mobile">
            <b-dropdown v-model="timeframe" aria-role="list" position="is-bottom-left">
              <!--    icon-left="earth"        icon-left="account-multiple" -->

              <template #trigger>
                <b-button
                  v-show="timeframe == 0"
                  label="All dates"
                  type="is-light"
                  icon-pack="fal"
                  size="is-small"
                  icon-right="chevron-down"
                />
                <b-button
                  v-show="timeframe == 30"
                  label="Last 30 days"
                  icon-pack="fal"
                  type="is-light"
                  size="is-small"
                  icon-right="chevron-down"
                />
                <b-button
                  v-show="timeframe == 7"
                  label="Last 7 days"
                  icon-pack="fal"
                  type="is-light"
                  size="is-small"
                  icon-right="chevron-down"
                />
                <b-button
                  v-show="timeframe == 1"
                  label="Last 24 hours"
                  icon-pack="fal"
                  type="is-light"
                  size="is-small"
                  icon-right="chevron-down"
                />
              </template>

              <b-dropdown-item :value="30" aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="calendar" icon-pack="fal"></b-icon>
                  <div class="media-content">
                    <h3>Last 30 days</h3>
                    <small>Monthly traffic overview</small>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item :value="7" aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="calendar" icon-pack="fas"></b-icon>
                  <div class="media-content">
                    <h3>Last 7 days</h3>
                    <small>Weekly traffic overview</small>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item :value="1" aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="calendar" icon-pack="fas"></b-icon>
                  <div class="media-content">
                    <h3>Last 24 hours</h3>
                    <small>Today's traffic</small>
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item :value="0" aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="account-multiple" icon-pack="fal"></b-icon>
                  <div class="media-content">
                    <h3>All traffic</h3>
                    <small>Since you joined</small>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <section class="info-tiles statsTiles">
          <div class="tile is-ancestor has-text-centered">
            <!--
                      <router-link to="/stats" class="tile is-parent t1" >
                          <article class="tile is-child box">
                              <p class="title">xxx</p>
                              <p class="subtitle">Posts traffic</p>
                              <p class="mention">XXX% Decrease from last month</p>
                          </article>
                      </router-link>
                    -->

            <!-- <router-link to="/stats" class="tile is-parent t1" > -->
            <div class="tile is-parent t1">
              <article class="tile is-child box">
                <p class="title">
                  <numAbv :num="codeViews" :digit="1" />
                </p>
                <p class="subtitle">Traffic</p>
                <p class="mention" v-show="codeViewsIncrease">
                  {{ codeViewsIncrease }}% {{ codeViewsIncrease }} from last {{ weekMonthYear }}
                  <span class="icon" v-if="codeViewsIncreaseUp">
                    <i class="fad fa-chart-line"></i>
                  </span>
                  <span class="icon" v-if="codeViewsIncreaseDown">
                    <i class="fad fa-chart-line-down"></i>
                  </span>
                </p>
              </article>
            </div>

            <div @click="isImageModalProfileActive = !isImageModalProfileActive" class="tile is-parent t2">
              <article class="tile is-child box">
                <p class="title">
                  <numAbv :num="profileViews" :digit="1" />
                </p>
                <p class="subtitle">Revenue</p>
                <p class="mention" v-show="profileViewsIncrease">
                  {{ profileViewsIncrease }}% {{ profileViewsIncrease }} from last {{ weekMonthYear }}
                  <span class="icon" v-if="profileViewsIncreaseUp">
                    <i class="fad fa-chart-line"></i>
                  </span>
                  <span class="icon" v-if="profileViewsIncreaseDown">
                    <i class="fad fa-chart-line-down"></i>
                  </span>
                </p>
              </article>
            </div>

            <router-link to="/rewards" class="tile is-parent t3">
              <article class="tile is-child box">
                <p class="title">{{ tokenUsedTimeframe }}</p>
                <p class="subtitle">Tokens used</p>
                <p class="mention" v-if="tokenUsedTimeframe">from {{}} rewards</p>
                <p class="mention" v-else>{{ rewardsEarnedTotal }} that's aprox $2.00</p>
              </article>
            </router-link>
            <router-link to="/invite" class="tile is-parent t4">
              <article class="tile is-child box">
                <p class="title">{{ invitedFriendsTimeframe.length }}</p>
                <p class="subtitle">XXXX</p>
                <p class="mention" v-if="!invitedFriendsRewards.length && !invitedFriendsTimeframe.length">
                  This is yyyyy
                </p>
                <p class="mention" v-show="timeframe && invitedFriendsRewards.length">
                  {{ invitedFriendsRewards.length }} total invited users
                </p>
              </article>
            </router-link>
          </div>
        </section>

        <hr />

        <hr />
        <h2 class="title is-4">Subscribers</h2>

        <br />
        <hr />
        <h2 class="title is-4">Subscribers</h2>

        <br />
        <hr />
        <h2 class="title is-4">Donations</h2>

        <br />
        <hr />
        <h2 class="title is-4">Hosting costs</h2>

        <!-- 
        <b-field horizontal label="discordBotChannelId">
          <b-input
            size="is-"
            placeholder="123456789"
            v-model="bot.discordBotChannelId"
            :loading="posting"
            :disabled="posting"
            type="text"
          ></b-input>
        </b-field> -->

        <!-- END     
    -->
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    // headerManage,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeframe: 30,
      //dummy
      referralCodesCount: 0,
      invitedFriendsRewards: [],
      invitedFriendsTimeframe: [],

      discussionsCount: 0,
      referrals: [],
      selectedRepostProd: null,
      profileReport: {},
      codeViewsReport: {},
      codeViewsReportRaw: {},
      isImageModalProfileActive: false,
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  computed: {
    tokenUsedTimeframe() {
      return this.bot?.tokenUsedTimeframe || 0;
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style>
/**/
.dashboardIconSummary .button {
  float: left;
  margin-right: 10px;
  box-shadow: none;
}

.dashboardIconSummary.b1 .button {
  background: #ebfdfe;
}
.dashboardIconSummary.b2 .button {
  background: #f3f0fe;
}
.dashboardIconSummary.b3 .button {
  background: #fffce2;
}

.dashboardIconSummary p {
  text-transform: uppercase;
  font-size: 10px;
  color: #777;
}

.info-tiles .tile.is-child {
  box-shadow: none;
  border-radius: 20px;
  border: 1px white solid;
}
.info-tiles .tile.t1 .is-child {
  background: #ebfdfe;
}
.info-tiles .tile.t2 .is-child {
  background: #f3f0fe;
}
.info-tiles .tile.t3 .is-child {
  background: #f1fcef;
}
.info-tiles .tile.t4 .is-child {
  background: #fffaf6;
}
.info-tiles .title {
  font-size: 24px;
}

.info-tiles p.subtitle {
  font-size: 18px;
  opacity: 1;
  margin-bottom: 0.2em;
}
.info-tiles p.mention {
  font-size: 12px;
  opacity: 0.5;
}

.info-tiles .tile.is-child:hover {
  background: #fff;
  border: 1px blue solid;
}

@media screen and (max-width: 1150px) {
  .statsTiles .info-tiles .title {
    font-size: 18px;
  }
  .statsTiles .info-tiles .subtitle {
    font-size: 14px;
  }
}

/* */
@media screen and (max-width: 1150px) {
  .statsTiles .tile.is-parent {
    width: 50%;
    display: inline-flex;
  }
}
</style>
